@import '../fonts/monserratu/stylesheet.scss';
@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';
@import "custom";
@import "~bootstrap/scss/bootstrap";
$font:$font-family-sans-serif;
$off-white:#e3e8ef;

body {
    position: relative;
}

.btn-xl{
            padding:0.9em 2.2em;
            border-radius:2em;
            font-size: 1.5rem;
            letter-spacing: 0.04em;
            font-weight: bold;
            
}
.btn-success:hover{
    background-color: #26cb67;
    border-color: #26cb67;
}
.nowrap { white-space: nowrap; }
#drawer{
    position: fixed;
    top: 0;
    left: 0;
    width: 25%;
    background-color: darken($blue,2%);

    color:#fff;
    height: 100vh;
    transform:translateX(-100.0%);
    .drawer-inner {
        width: 100%;
    }
    .drawer-inner-top{
        flex:7;
    background-image: url('../images/drawer-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 140%;
    }
    .drawer-inner-bottom{
        flex:3;
        background-color: #122247;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .job-board-button{
        font-size: 0.9em;
        font-weight: bold;
        letter-spacing: 0.08em;
        border-radius: 2.1em;
        padding: 1.2em 2.4em  1.2em 2.75em;
        vertical-align: middle;
        &:after{
            content:'';
            width: 24px;
            height: 24px;
            background-image: url('../images/check-icon.svg');
            background-size: 100%;
            margin-left: 1em;
            display: inline-block;
            vertical-align: middle;
        }
    }
    #drawer-logo {
        margin: auto;
        padding-top: 2.6em;
        height:auto;
    }
     #drawer-logo, .hamburger{
          transform: translateY(-100px);

        
    }
    .hamburger{
        position: absolute;
        top:2.4em;
        left:2.0em;
    }
    nav {
        padding-top: 1.2em;
          transform: translateX(-100%);
        flex-direction: column;
    }
    nav a{
        color:#fff;
        display: block;
        padding-top:1em;
        padding-bottom: 1em;
        padding-left: 2.75em;
        text-transform: uppercase;
        font-size: 0.9em;
         letter-spacing: 0.08em;
        &.active{
 border-left:3px solid $green;
        }
       
       
    }

}


body.loaded{
        #drawer{

        transition:transform 0.8s cubic-bezier(.82,.01,.17,.99);

        #drawer-logo, .hamburger{

        transition:transform 0.8s cubic-bezier(.82,.01,.17,.99);   
        }

        nav {
 
        transition:transform 0.8s cubic-bezier(.82,.01,.17,.99);
        }

    }
}
#view{
    transition:transform 0.8s cubic-bezier(.82,.01,.17,.99);
}
body.drawer-open{
    #view{
        transform:translateX(25%);
    }
    #drawer{
         transform:translateX(0%);
        #drawer-logo, .hamburger{
            transform: translateY(0);
        }
        nav{
            transform: translateX(0%);
            
        }
    }

    #top-nav{
        transform:translateY(-200px);
    }
}
#top-nav{
    padding-left: 4.5em;
    padding-right: 4.5em;
    min-height: 75px;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    margin-top: 3em;
    z-index: 10;
    transition: padding 0.4s cubic-bezier(0,0,.02,.98),transform 0.8s cubic-bezier(.82,.01,.17,.99);
    nav{
        a{
            color:#fff;
            padding-top: 2em;
            padding-bottom: 2em;
            padding-left: 0.95em;
            padding-right: 0.95em;
            height:100px;
            display: inline-block;
            line-height: 2.8;
            position: relative;
            font-size: 0.9em;

                      &:hover{
                          &:after{
                            content:'';
                            display: block;
                            position: absolute;
                            height: 3px;
                            border-radius: 3px;
                            width:50px;
                            left:50%;
                            margin-left: -25px;
                            bottom:15px;
                            background-color: $green;
                            animation:0.4s grow-x cubic-bezier(0,0,.02,.98);
                          }
                        
                    
                    }
        }
        
        letter-spacing: 0.04em;
    }
    nav, .btn{
        text-transform: uppercase;
    }
    .cta{
        padding:0.75em 2.2em;
        letter-spacing: 0.04em;
        font-weight: bold;
        border-radius: 2em;
    }
}
body.scrolled {
    #top-nav{
        
        margin-top: 0;
        padding-left: 2.2em;
        padding-right: 2.2em;
        position: fixed;
        background-color: #fff;
        box-shadow: 0 1px 20px rgba( #557ffd,0.2 );
        nav{
            a{
                color:$blue;
                position: relative;
                &:hover{
                    color:#909dc4;
                    &:after{
                        content:none;
                    }
                }            
                &.active{
                    font-weight: bold;
                    &:after{
                        content:'';
                        display: block;
                        position: absolute;
                        height: 3px;
                        border-radius: 3px;
                        width:50px;
                        left:50%;
                        margin-left: -25px;
                        bottom:0px;
                        background-color: $green;
                        animation:0.4s grow-x cubic-bezier(0,0,.02,.98);
                    
                    }
                }

            }

        }
        .navlogosvg-st0 {
            // fill: $blue;
            fill: $blue;
        }

        .hamburger span {

        background-color: $blue;
        }   
    }
}


h2 {
    text-transform: uppercase;
    letter-spacing: 0.04em;
    &.section-title:after{
        content:'';
        display: block;
        height: 3px;
        border-radius: 3px;
        width:40px;
         margin:1em auto;
        background-color: $green;
       
    }
}
.lead{
    color:$blue;
}
.lead.lead-lg{
    font-size: 1.65em;
    line-height: 1.8;
}
.lead.lengthy{
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.04em;
}


body.home{
    .btn{
        text-transform: uppercase;
    }
    .spot-illustration img{
            height:180px;
            max-width:430px;
            opacity:1;
    }
    #hero {
        min-height:100vh;
        background: url('../images/hero.jpg') center;
        background-size: cover;
        display: flex;
        align-items: center;
        color:#fff;
        text-transform: uppercase;
        .btn{
            margin-top: 10vh;

        }
    
        h1{
            font-size: 4em;
            letter-spacing: 0.04em;
            font-weight: normal;
            color:#fff;
            strong{
                font-weight: bold;
            }
        }


    }
    #footer{
         background-color: darken($blue,3%);
         color:#fff;
         padding: 4em 4em;
         overflow: hidden;
        .row {
            align-items: center;
        }
        .footer-details {
             padding-left: 2em;
             font-size: 0.7em;
             margin-top: 2em;
             .phone-number {
                 font-size: 1.3em;
             }
             address{
                 margin-right: 3em;
                 span{
                     color:$green;
                 }
             }


         }
         .careers-btn{
             font-size: 0.95em;
             
             letter-spacing: 0.08em;
             padding:0.5rem 0.75rem;
             &:hover{
                 color:$blue;
             }
         }
         

    }
    h5{
        text-transform: uppercase;
        letter-spacing: 0.04em;
    }
    .how-it-works{
        .spot-illustration {
            text-align: center;
            margin-bottom: 3em;
        }
        .steps {
            margin-top: 4em;
            margin-bottom: 7em;
            .step{
                position: relative;
                &:after{
                    position: absolute;
                    right:-26px;
                    top:0;
                    content:'';
                    display: block;
                    height: 3px;
                    border-radius: 3px;
                    width:55px;
                    margin:1em auto;
                    background-color: $green;
                
                }
                &:last-child {
                    &:after{
                        content:none;
                    }
                }
                .num{
                    font-size: 1.65em;
                    font-weight: 100;
                    padding:0.5em;
                    width:50px;
                    font-weight: normal;
                    text-align: center;
                    height:50px;
                    margin: auto;
                    display: block;
                }
            }
            h5{
                text-align: center;
                margin-top: 2em;
                margin-bottom: 2em;
            }
        }
    }
    .jumbotron.psa{
        margin-top: 2.8em;
        background-image: url('../images/blue-corner.svg') , url('../images/green-corner.svg');
        background-repeat: no-repeat;
        background-size: 212px, 145px;
        background-position: right 0.8em top, bottom left;
        h3{
            font-weight: normal;
        }
        .lead{
            font-weight: normal;
            font-size: 1em;
            max-width: 660px;
            margin: auto;
            margin-top: 1.65em;
            margin-bottom: 2.1em;
            line-height: 1.75;
            
        }
    }
    section{
        padding-top: 3%;
        padding-bottom: 3%;
    }
    section.dark{
        background-color: $blue;
        color:#fff;
        h2, .lead {
            color:#fff;
        }
        .jumbotron{

            background-color: darken($blue,3%);
        }
    }
    #get-started{
            margin-top: 4em;
           padding-left:6%;
            padding-right: 6%;

        .row{
            margin-bottom: 2.8em;
            .col-lg {
                &:first-child{
                    padding-right: 12px;
                }
                &:last-child{
                    padding-left: 12px;
                }
            }
            
        }

        .form-control-lg, .custom-select-lg{
            padding: 0.5rem 1rem;
            font-size: 1.4em;
            padding: 0.85em 1.5em;
            border-radius: 1.8em;

        }
        .custom-select-lg{
      height: 4.65rem;
      color:$blue;
        }
        .custom-select{
            background-image: url('../images/select-arrow.svg');
            background-size: 22px 22px;
            background-position: right 1.2em center;
        }

    }
    form{
        label  {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 0.08em;
            padding-left: 2.3em;
        }

    }
    .suggested-action   {
        margin-top: 2.5em;
        margin-bottom: 3em;
    }
}

body.pricing {
  .container {
    max-width: 1260px;
  }
  .btn{
    text-transform: uppercase;
  }
  #hero {
    min-height:62vh;
    background: url('../images/hero-pricing.jpg') center;
    background-size: cover;
    display: flex;
    align-items: center;
    color:#fff;
    text-transform: uppercase;
    .message {
      top: auto;
      position: initial;
      margin-top: 230px;
      h1 {
        font-size:40px;
        text-align:center;
      }
      h1:after {
        content: '';
        display: block;
        height: 3px;
        border-radius: 3px;
        width: 40px;
        margin: 1em auto;
        background-color: #0ea94b;
      }
      h2 {
        font-size:33px;
        text-transform: initial;
        color:#fff;
        font-family: 'MontserratU';
        font-weight:300;
        text-align:center;
      }
      p {
        font-size:17px;
        text-transform:initial;
        text-align: center;
        font-family: 'MontserratU';
        font-weight: normal;
      }
    }
  }

  .row { margin-left:0; margin-right:0; }

  #subscription-details {
    .details {
      display:none;
      margin-bottom:60px;
      padding:50px 60px;
      background-color:#fff;
      border-radius:8px;
      .table {
        display:table;
        color:#182958;
        .table-row {
          display: table-row;
          .table-cell {
            display: table-cell;
            padding:15px 5px;
            font-size:14px;
            border-top:1px solid #d0dae5;
          }
          .table-cell.green {
            width:150px;
            font-size:18px;
            line-height:24px;
            color:#0ea94b;
            text-align:center;
          }
          .table-cell.num {
            font-size:24px;
          }
          .table-cell.check {
            img {
              height:14px;
              width:auto;
            }
          }
        }
        .table-header {
          .table-cell {
            font-weight:bold;
            border-top:none;
            text-align:center;
          }
        }
        .table-subheader {
          .table-cell {
            padding-top:50px;
            font-weight:bold;
            text-transform: uppercase;
          }
        }
      }
    }
    .guarantee {
      display:flex;
      align-items: center;
      justify-content: center;
      margin:0 auto;
      font-size:16px;
      font-weight:300;
      line-height: 2em;
      div {
        padding:12px;
      }
      img.totalhirelogo {
        width:76px;
        height:76px;
      }
      span {
        display:block;
        font-size:24px;
        font-weight:normal;
      }
    }
  }
  #faq {
    .col-lg {
      text-align: left;
    }
    p { margin-bottom:50px; }
    strong { text-transform:uppercase; }
  }
}

#pricing-plans{
  padding-top: 1.5%;
  padding-bottom:1%;
  .pricing {
    display:flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
    width:100%;
    .plan {
      flex: 0 0 31%;
      padding:40px;
      font-size:16px;
      color:#182958;
      text-align:left;
      background-size:526px;
      background-repeat:no-repeat;
      background-position:44px 17px;
      border-radius:8px;
    }
    .plan.standard {
      background-color:#ffffff;
      background-image:url('../images/check-icon-standard.svg');
      border:2px solid #d0dae5;
      ul li {
        background: url('../images/check-bullet-standard.svg') no-repeat top left;
      }
    }
    .plan.premium {
      background-color:#d0dae5;
      background-image:url('../images/check-icon-premium.svg');
      ul li {
        background: url('../images/check-bullet-premium.svg') no-repeat top left;
      }
    }
    .plan.enterprise {
      color:#ffffff;
      background-color:#243668;
      background-image:url('../images/check-icon-enterprise.svg');
      .button {
        text-align: center;
        a {
          color:#182958;
          background-color:#fff;
          border-color:#fff;
        }
        a:hover {
          opacity:0.85;
        }
      }
      ul li {
        background: url('../images/check-bullet-enterprise.svg') no-repeat top left;
      }
    }
    .tier {
      font-weight:bold;
    }
    .price-desc {
      display:flex;
      align-items: flex-start;
      margin-bottom:50px;
      min-height:98px;
      .price {
        font-size:54px;
        line-height:54px;
        font-weight:300;
        padding-right:35px;
        sup {
          font-size:32px;
          font-weight:300;
        }
        .price-year {
          font-size:14px;
          font-weight:bold;
          line-height: 24px;
          text-align:right;
          text-transform: uppercase;
          sup {
            font-size:10px;
            font-weight:300;
          }
        }
        span {
          font-size: 12px;
          font-weight:normal;
        }
      }
      .desc {
        line-height:18px;
        font-weight: 300;
      }
    }
    .button {
      margin-bottom:50px;
      text-align:center;
      a {
        padding: 0.75em 2.2em;
        letter-spacing: 0.04em;
        font-weight: bold;
        border-radius: 2em;
      }
    }
    ul{
      margin-left: 0;
      list-style-type: none;
      padding: 0;
      margin-top: 1em;
      margin-bottom: 1em;
      li{
        background-size: 30px !important;
        padding-left: 3.75em;
        margin-left: 0;
        list-style-type: none;
        margin-top: 1em;
        margin-bottom: 1em;
        line-height:1.5em;
        min-height: 30px;
      }
    }
  }
  .view-details {
    display:inline-block;
    padding-right:42px;
    font-size:16px;
    font-weight:bold;
    text-decoration:none;
    color:#243668;
    text-transform:uppercase;
    background: url('../images/down-arrow.svg') no-repeat center right;
    background-size:24px 14px;
  }

  .currency-select {
    margin-bottom: 1.5%;
    display:inline-block;
    font-size:14px;
    line-height:14px;
    border:2px solid #d0dae5;
    border-radius: 2em;
    span {
      cursor:pointer;
      display:inline-block;
      padding: 8px 18px;
      color:#8599af;
      background-color:transparent;
      border-radius: 2em;
    }
  }
  .pricing {
    .usd { display:none; }
    .cdn { display:none; }
  }
}
#pricing-plans.usd {
  .currency-select {
    #usd {
      color:#ffffff;
      background-color:#596a9d;
    }
    #cdn {
      padding-right:8px;
    }
  }
  .pricing {
    .usd { display: block; }
    .cdn { display: none; }
  }
}
#pricing-plans.cdn {
  .currency-select {
    #cdn {
      color:#ffffff;
      background-color:#596a9d;
    }
    #usd {
      padding-left:8px;
    }
  }
  .pricing {
    .cdn { display:block; }
    .usd { display:none; }
  }
}

#view-the-process {
    background-color: $gray-200;
}

#employer-view {
    position:relative;
    overflow: hidden;
    background-image:url('../images/green-rounded-shape.svg');
    background-repeat:no-repeat;
    background-position:right bottom 80px;
    background-size:623px 1040px;
    h2 {
        margin-bottom:100px;
    }
    .col-lg.txt {
        max-width:420px;
        width:100%;
        text-shadow: 0 0 1px $blue;
        .lead{
            margin-bottom: 3rem;
        }
    }
    .col-lg.img {
        width:60%;
        img {
            position:absolute;
            top:0;
            left:0;
            width:1345px;
            height:auto;
        }
    }
}

#why-use {
    background-color: $darkblue;
    .col-lg {
             margin-bottom: 80px;
         }
    h2 {
        font-weight: normal;
        color: $white;
        margin-bottom: 3rem;
    }
    h6 {
        font-weight: normal;
        color: $white;
        min-height:80px;
        text-transform:uppercase;
        line-height: 1.6rem;
    }
    .icon {
        text-align: center;
        margin-top: 1em;
        margin-bottom: 2em;
        img {
            max-height: 70px;
        }
    }
}

#top-features{
    h2 {
        font-weight: normal;
        color: $white;
        margin-bottom: 5rem;
    }
    ul{
        margin-left: 0;
        list-style-type: none;
        padding: 0;
        margin-top:0;
        margin-bottom:0;
        li{
            background: url('../images/check-bullet.svg') no-repeat top left;
            background-size: 30px;
            padding-left: 3.75em;
            margin-left: 0;
            list-style-type: none;
            margin-top: 1em;
            margin-bottom: 1em;
            font-size:$font-size-sm;
            text-transform: uppercase;
        }
        li:first-child {
            margin-top:0;
        }
    }
}

#key-features{
    background-color:$off-white ;
        h5{
            margin-top: 2em;
            margin-bottom: 2em;
        }
        .feature-cell{
  margin-top: 1.5em;
            margin-bottom: 1.5em;          
        }
        .icon{
            text-align: center;
            margin-top: 1em;
            margin-bottom: 1em;
            img{
                max-height:70px;
            }
            
        }
        ul{
                        margin-left: 0;
            list-style-type: none;
            padding: 0;
            margin-top: 1em;
            margin-bottom: 1em;
        }
        li{
            background: url('../images/check-bullet.svg') no-repeat top left;
            background-size: 30px;
            padding-left: 3.75em;
            margin-left: 0;
            list-style-type: none;
            margin-top: 1em;
            margin-bottom: 1em;
        }

}

#integrated-partners {
    .py-5 {
       padding-bottom:0.5rem !important;
    }
    .lead.lengthy {
        text-transform: initial;
        line-height: 2em;
        font-size: 1.6em;
        font-weight:300;
        strong {
            display:block;
            padding-top:15px;
        }
    }
    .partner {
        margin:25px 0;
        text-align: center;
        font-size:2.38em;
        color:#172757;
    }
    .mitre img { max-width:313px; width:100%; height:auto; }
    .indeed img { max-width:193px; width:100%; height:auto; }
    .google-jobs img { max-width:299px; width:100%; height:auto; }
    .salesforce img { max-width:194px; width:100%; height:auto; }
    .google-analytics img { max-width:386px; width:100%; height:auto; }
    .facebook img { max-width:230px; width:100%; height:auto; }
    .dropbox img { max-width:275px; width:100%; height:auto; }
    ul {
        padding-bottom:20px;
        li {
            margin-bottom:2.2rem;
            font-size:1.1em;
            color:#172757;
        }
    }
}

#who-is-using{
    background-color:$off-white ;
    .container{
        max-width: 1440px;
    }


}
.partner-logos{
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    padding-bottom: 4em;
    li{
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        a{
            display: block;
            width: 332px;
            height: 168px;
            &.tim-hortons{
                background-image: url('../images/partners/off/tim-hortons.png');
                &:hover{
                    background-image: url('../images/partners/on/tim-hortons.png');
                }
            }
            &.boston-pizza{
                background-image: url('../images/partners/off/boston-pizza.png');
                &:hover{
                    background-image: url('../images/partners/on/boston-pizza.png');
                }
            }
            &.kelseys{
                background-image: url('../images/partners/off/kelseys.png');
                &:hover{
                    background-image: url('../images/partners/on/kelseys.png');
                }
            }
            &.pg{
                background-image: url('../images/partners/off/pg.png');
                &:hover{
                    background-image: url('../images/partners/on/pg.png');
                }
            }
            &.wendys{
                background-image: url('../images/partners/off/wendys.png');
                &:hover{
                    background-image: url('../images/partners/on/wendys.png');
                }
            }
            &.slc{
                background-image: url('../images/partners/off/slc.png');
                &:hover{
                    background-image: url('../images/partners/on/slc.png');
                }
            }
            &.home-hardware{
                background-image: url('../images/partners/off/home-hardware.png');
                &:hover{
                    background-image: url('../images/partners/on/home-hardware.png');
                }
            }
            &.hoco{
                background-image: url('../images/partners/off/hoco.png');
                &:hover{
                    background-image: url('../images/partners/on/hoco.png');
                }
            }
            &.comfort-inn{
                background-image: url('../images/partners/off/comfort-inn.png');
                &:hover{
                    background-image: url('../images/partners/on/comfort-inn.png');
                }
            }
            &.skywheel{
                background-image: url('../images/partners/off/skywheel.png');
                &:hover{
                    background-image: url('../images/partners/on/skywheel.png');
                }
            }
            &.eec{
                background-image: url('../images/partners/off/eec.png');
                &:hover{
                    background-image: url('../images/partners/on/eec.png');
                }
            }
            &.movieland{
                background-image: url('../images/partners/off/movieland.png');
                &:hover{
                    background-image: url('../images/partners/on/movieland.png');
                }
            }
            &.commonwell{
                background-image: url('../images/partners/off/commonwell.png');
                &:hover{
                    background-image: url('../images/partners/on/commonwell.png');
                }
            }
            &.dinosaur{
                background-image: url('../images/partners/off/dinosaur.png');
                &:hover{
                    background-image: url('../images/partners/on/dinosaur.png');
                }
            }
            &.midway{
                background-image: url('../images/partners/off/midway.png');
                &:hover{
                    background-image: url('../images/partners/on/midway.png');
                }
            }

        }
    }
}

.conclusion{
    .spot-illustration {
        text-align: center;
     
        margin-bottom: 3em;
    }
}
.nav-logo {
    height:36px;
    display: block;
    width:225px;
    margin-left: 1.5em;
    padding-top: 2px;
}

.navlogosvg-st0 {
    // fill: $blue;
    fill: #fff;
}

.navlogosvg-st1 {
    fill: #0EA94B;
}

.nav-logo {
}
.social-links {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 1.8em;
    line-height: 1.2;
    li{
        margin: 0;
    
    margin-left: 0.4em;
    padding: 0;
    margin-bottom: 0.6em;
    
    
    list-style-type: none;
    display: inline-block; 
    a{
        cursor: pointer;
        color:#fff;
        &:hover{
            color:#96a9e1;
        }
    }     
    }
}
.copyright{
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}
#responses{
    .response{
        text-align: center;
        border:2px solid $green;
        border-radius:2.2em;
        font-size:1.25em;
        padding:1.0em;
    }
}
$ham-height: 18px;
.hamburger{
  width: 25px;
  height: $ham-height;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

 .hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0px;
}

.hamburger span:nth-child(2),.hamburger span:nth-child(3) {
  top: $ham-height*0.5 - 1;
  width:22px;
}

.hamburger span:nth-child(4) {
  top: $ham-height - 1;
}

body.drawer-open .hamburger span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

body.drawer-open .hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.drawer-open .hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

body.drawer-open .hamburger span:nth-child(4) {
  top: $ham-height*0.333;
  width: 0%;
  left: 50%;
}

@keyframes slide-in-from-top {
    0% { transform: translateY( -100px); } 
    100% {transform: translateY( 0px); } 
}
@keyframes grow-x {
    0% { transform: scaleX( 0 ); } 
    100% {transform: scaleX( 1 ); } 
}

@media only screen and (max-width: 1570px) {

#drawer {
    width: 40%;
}
body.drawer-open{
    overflow: hidden;
    #view{
        transform:translateX(40%);
    }
}

}



@media only screen and (max-width: 1172px) {
  #top-nav{
      nav {
          display: none;
      }
  }
#drawer {
    width: 85%;
}
body.drawer-open{
    #view{
        transform:translateX(85%);
    }
}
.partner-logos li a{
    width: 332px * 0.70;
    height: 168px * 0.70;
    background-size: contain;
}

    #employer-view {
        background-position: right -50px bottom 80px;
    }

}

@media only screen and (max-width: 1200px) {
  body.pricing #pricing-plans .pricing .plan {
    padding: 3.5%;
  }
  body.pricing #pricing-plans .pricing .price-desc .price {
    padding-right: 8%;
  }
}

@media only screen and (max-width: 1050px) {
  body.pricing #pricing-plans .pricing .plan {
    padding: 2%;
  }
  body.pricing #pricing-plans .pricing .price-desc .price {
    padding-right: 5%;
  }
}

@media only screen and (max-width: 960px) {
  body.pricing .container{
    padding-left:15px;
    padding-right:15px;
  }

  body.pricing #pricing-plans {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  body.pricing #pricing-plans .currency-select {
    margin-bottom:3%;
  }
  body.pricing #pricing-plans .pricing {
    flex-wrap:wrap;
    max-width: 480px;
    margin: 0 auto;
  }
  body.pricing #pricing-plans .pricing .plan {
    flex: 0 0 100%;
    padding: 10%;
    margin-bottom:50px;
  }
  body.pricing #pricing-plans .pricing .price-desc {
    min-height: initial;
  }
  body.pricing #pricing-plans .pricing .price-desc .price {
    padding-right: 35px;
  }
  body.pricing #pricing-plans .pricing .plan.enterprise .button a {
    white-space: initial;
  }
  body.pricing #subscription-details .details {
    padding: 36px 20px;
  }
}

@media only screen and (max-width: 600px) {
  body.pricing #subscription-details .details .table .table-row .table-cell {
    padding: 10px 2px;
    font-size: 12px;
    line-height: 1.5;
  }
  body.pricing #subscription-details .details .table .table-row .table-cell.green {
    line-height: 1.5;
  }
  body.pricing #subscription-details .details .table .table-row .table-cell.num {
    font-size: 16px;
  }
  body.pricing #subscription-details .details .table .table-row .table-cell.green {
    font-size: 12px;
  }
  body.pricing #subscription-details .details .table .table-row .table-cell.check img {
    height: 10px;
  }
}

@media only screen and (max-width: 465px) {
  body.pricing #subscription-details .details {
    overflow:auto;
    height:50vh;
  }
}

@media only screen and (max-width: 380px) {
  body.pricing .captcha-area { margin-left:-20px; }
}

@media only screen and (max-width: 992px) {
    body.home .how-it-works .steps .step:after {
       position: static;
      
       margin-top: 3em;
       margin-bottom: 3em;
    }
    body.home #get-started .row .col-lg:first-child, body.home #get-started .row .col-lg:last-child {
        padding-left: 30px;
        padding-right: 30px;
    }
    body.home #get-started .row{
        margin-bottom: 0;
    }
    body.home #get-started .row .col-lg{
        margin-bottom: 1em;
    }
    body.home .jumbotron.psa {
        padding:13%;
    }
    #employer-view {
        background-position: right -50px bottom 20px;
        .col-lg.img {
            position: absolute;
            top: 300px;
            right: -50px;
        }
    }
}

@media only screen and (max-width: 768px) {
    #top-nav .cta {
        display: none;
    }
    #nav-logo{
        margin: auto;
    }
    // #top-nav .hamburger{
    //     display: block;
    //     float: left;
    //     margin-top: 1em;
    // }
    body.home .jumbotron.psa {
        padding:21% 13% 25%;
    }
    body.home .spot-illustration img {
        max-width:100%;
    }

    #employer-view .col-lg.img {
        position: absolute;
        top: 300px;
        right: -100px;
    }

}




@media only screen and (max-width: 575px) {

    body.home #hero h1 {
        font-size: 3.0em;

        margin: auto;
    }
    .btn-xl{
        font-size: 1.25rem;
    }
     body.home #hero .message{
         width: 90%;
        margin: auto;
     }

     .partner-logos li a{
        width: 332px * 0.5;
        height: 168px * 0.5;
        background-size: contain;
    }

    #employer-view {
        h2 {
            margin-bottom: 0.5rem;
        }
        .col-lg.txt {
            max-width: 100%;
            width: 100%;
        }
        .col-lg.img {
            position: initial;
            width:100%;
            img {
                position: initial;
                width:100%;
            }
        }
    }


}

@keyframes partner-logos {
    0% { transform: translateX( 0px ); } 
    100% {transform: translateX( -1 * (332px * 0.5) * 14 ); } 
}

@media only screen and (max-width: 480px) {
    #who-is-using .container {
        padding: 0;
        h2{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .partner-logos li{
        float: left;
    }
    .partner-logos-wrap{
        width: 100%;
        overflow-x: hidden;
    }
    .partner-logos{
        animation: partner-logos 30s linear infinite alternate;
         width: (332px * 0.5) * 15;
    }
    #top-nav{
        margin-top: 0.75em;
        padding-right: 2.5em;
        padding-left: 2.5em;
    }
    body.home #hero h1 {
        font-size: 2.6em;
        margin: auto;
    }
    body.home #hero p.lead {
        text-align: center;
    }
    #nav-logo{
        width: 185px;
        padding-top: 6px;
    }
    #drawer #drawer-logo {
        width: 170px;
        padding-top:   2.75em;
    }
    h2.section-title {
        font-size: 1.7em;
    }
    .lead{
        font-size: 1.0rem;
    }
    .lead.lead-lg{
        font-size: 1.4em;
    }
    h3{
      font-size: 1.5rem;
    }
    body.home #get-started .form-control-lg, body.home #get-started .input-group-lg > .form-control, body.home #get-started .input-group-lg > .input-group-prepend > .input-group-text, body.home #get-started .input-group-lg > .input-group-append > .input-group-text, body.home #get-started .input-group-lg > .input-group-prepend > .btn, body.home #get-started .input-group-lg > .input-group-append > .btn, body.home #get-started .custom-select-lg{
           font-size: 1.0em;     
    }
    body.home form label{
 
      padding-left: 1.1em;
    font-size: 0.9em;
    }
    body.home .jumbotron.psa{
            padding: 6em 8%;
                background-size: 212px, 109px;

    }
    body.home #get-started .custom-select-lg {
    height: 3.60rem;
    }
body.home #footer {
    background-color: #15234c;
    color: #fff;
    padding: 2em 3%;
}
body.home #footer .footer-details{
    text-align: center;
}
.social-links{
    text-align: center;
    padding-top: 1em;
}
#nav-bottom-logo{
    margin: auto;
}
}
@media only screen and (max-width: 400px) { 
    body.home #hero .message{
        width: auto;
    }
    .btn-xl{
        font-size: 1.08rem;
    }
}

#indeed-badge {
    z-index:110;
    position:fixed;
    bottom:100px;
    right:-1px;
    width:116px;
    height:243px;
    overflow:hidden;
    background-image:url('../images/badge-bg.svg');
    background-size:116px 243px;
    background-repeat: no-repeat;
    background-position: right center;
    text-align:center;
    color:$white;
    animation: slide-in-from-right 1.5s forwards;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    div {
        margin-top:65px;
        padding-left:8px;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }
    .totalhirelogo {
        display:block;
        width:32px;
        height:32px;
        margin-bottom:10px;
        margin-left:auto;
        margin-right:auto;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }
    .indeedlogo {
        display:block;
        width:94px;
        height:26px;
        margin-bottom:10px;
        margin-left:auto;
        margin-right:auto;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }
    p {
        font-size:12px;
        line-height:1em;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }
}

@media only screen and (min-width: 1570px) {
    #indeed-badge {
        width: 174px;
        height: 365px;
        background-size:174px 365px;
        div {
            margin-top:98px;
            padding-left:12px;
        }
        .totalhirelogo {
            width:48px;
            height:48px;
            margin-bottom:15px;
        }
        .indeedlogo {
            width:141px;
            height:39px;
            margin-bottom:15px;
        }
        p {
            font-size:18px;
        }
    }
}
@media only screen and (max-width: 1345px) {
    #top-nav nav a {
        padding-left: 0.6em;
        padding-right: 0.6em;
        font-size: 0.85em;
        line-height: 3.2em;
    }
}
@media only screen and (max-width: 1265px) {
  #top-nav nav a {
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 0.75em;
    line-height: 4.2em;
  }
}
@media only screen and (max-width: 480px) {
    #indeed-badge {
        width: 87px;
        height: 183px;
        background-size:87px 183px;
        div {
            margin-top:49px;
            padding-left:6px;
        }
        .totalhirelogo {
            width:24px;
            height:24px;
            margin-bottom:7px;
        }
        .indeedlogo {
            width:70px;
            height:19px;
            margin-bottom:7px;
        }
        p {
            font-size:9px;
        }
    }
}

@keyframes slide-in-from-right {
    0%  { right: -245px; }
    50%  { right: -245px; }
    100% { right: -1px; }
}

@media only screen and (min-width: 641px) {
    body.home .spot-illustration { background-repeat:no-repeat; background-position:center center; background-size: contain; }
    body.home .spot-illustration.collect-applicants { background-image:url(../images/collect-applicants.gif); }
    body.home .spot-illustration.productivity { background-image:url(../images/productivity.gif); }
}

body.home .spot-illustration.collect-applicants img, body.home .spot-illustration.productivity img { opacity:0; }

@media only screen and (max-width: 640px) {
  body.home .spot-illustration.collect-applicants img, body.home .spot-illustration.productivity img {
    opacity: 1;
  }
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border:none; }

.modal-dialog {  width:90vw; max-width:1275px; }
.modal-content { border:none; background-color:transparent; }
.modal-body { padding:0; }
.modal-body section { background-color:transparent !important; padding:0 !important; }
.modal-body .getstarted { margin-bottom:0; padding: 5rem 6rem; }
.modal-body .getstarted h3 { color:#ffffff; }
.modal-body .getstarted .form-control-lg, .modal-body .getstarted .custom-select-lg { padding: 0.5rem 1rem; font-size: 1em; padding: 0.85em 1.5em; border-radius: 1.8em; }
.modal-body .getstarted .custom-select-lg {  color: #182958; }
.modal-body .getstarted form label { display:block; text-align:left; margin-top: 0.5rem; margin-bottom: 0; font-size: 14px; padding-left: 1.25em; }
.modal-body .getstarted .pricing .price-desc .desc { font-size:14px; }
.modal-body .getstarted .pricing .plan ul li { font-size:14px; }
.modal-body .getstarted .pricing .plan .more { display:none; }
.modal-body .getstarted .pricing .plan .viewall { text-align:center; text-align: center; font-weight:300; cursor:pointer; }
.modal-body .getstarted .step { display:none; }
.modal-body .getstarted .step1 { display:block; }
.modal-body .getstarted .step4 .row { align-items: center; justify-content: center; }
.modal-body .getstarted .options .option { position:relative; }
.modal-body .getstarted .options label { position:relative; display:block; padding:5px 5px 5px 35px; line-height: 1.85em; border:1px solid transparent; border-radius:25px; }
.modal-body .getstarted .options input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
.modal-body .getstarted .options label:before { content:""; display: block; position: absolute; top: 4px; left: 5px;  width:25px; height:25px; border:1px solid #707070; border-radius:25px; }
.modal-body .getstarted .options input:checked + label { border:1px solid #0ea94b; }
.modal-body .getstarted .options input:checked + label:before { background-image:url('../images/logo-only.svg'); border:none; }


.getstarted {
  .buttons {
    margin-top:50px;
    text-align: center;
    a {
      color: #fff;
      background-color: #0ea94b;
      border:1px solid #0ea94b;
      padding: 0.75em 2.2em;
      letter-spacing: 0.04em;
      font-weight: bold;
      border-radius: 2em;
    }
    a:hover {
      opacity:0.85;
      text-decoration:none;
    }
    a.back {
      background-color:transparent;
    }
  }
}
.selected-plan {
  border:3px solid #0ea94b;
  border-radius:15px;
  background-color:#ffffff;
  color:#18296e;
  padding:25px;
  margin:0 0 25px 0;
  .plan {
    color:#ffffff;
    background-color:#0ea94b;
    margin-left:-25px;
    border-radius: 0 5px 5px 0;
    line-height: 1.2em;
    font-weight: 300;
    padding:10px 25px;
    text-align: left;
    strong {
      font-weight:600;
    }
  }
  .price {
    font-size:54px;
    line-height:54px;
    font-weight:300;
    padding-right:35px;
    width: 150px;
    sup {
      font-size:32px;
      font-weight:300;
    }
    .price-year {
      font-size:14px;
      font-weight:bold;
      line-height: 24px;
      text-align:right;
      text-transform: uppercase;
      sup {
        font-size:10px;
        font-weight:300;
      }
    }
    span {
      font-size: 12px;
      font-weight:normal;
      line-height: 1em;
    }
  }
  .desc {
    line-height:18px;
    font-weight: 300;
  }
}
.view-plan {
  position:relative;
  border:3px solid #0ea94b;
  border-radius:15px;
  background-color:#ffffff;
  color:#18296e;
  padding:15px 25px 15px 50px;
  text-align:left;
  line-height: 1.2em;
  font-weight: 300;
  strong {
    font-weight:600;
  }
}
.view-plan:before { content:""; display: block; position: absolute; top: 50%; margin-top:-11px; left: 15px; width:25px; height:25px; border:2px solid #92aac4; border-radius:25px; background-color:#ffffff; }

.view-plan.premium {
  border:3px solid #314276;
  background-color:#d0dae5;
  color:#18296e;
}
.view-plan.enterprise {
  border:3px solid #5265a0;
  background-color:#314276;
  color:#ffffff;
}

body.landscape .message {
  top: 200px;
}

body.landscape section#top-features.dark .jumbotron {
    background-color: #ffffff;
    margin-top: 5em;
    margin-bottom: 5em;
}

body.landscape section#top-features.dark .jumbotron .lead {
    color: #182958;
}

body.landscape #hero {
    min-height: 100vh;
    background: url('../images/hero-landscape.jpg') center;
    background-size: cover;
    background-repeat:no-repeat;
}
body.landscape #hero h1 {
  font-size:3.2em;
}
body.landscape #hero h3 {
  color:#fff;
  font-weight:normal;
}

body.landscape .conclusion .col-md { margin-bottom:50px; }
body.landscape .why { position:relative; display:block; padding-left:85px; line-height:1.5em; margin-bottom: 50px; margin-left: 20px; margin-right: 20px; }
body.landscape .why strong { display:block; margin-bottom:5px; }
body.landscape .why1:before { content:"1"; position:absolute; top:0; left:0; width: 65px; height: 65px; padding: 10px; font-size: 42px; line-height: 48px; text-align: center; color:#ffffff; background-color:#0ea94b; border-radius:50%; }
body.landscape .why2:before { content:"2"; position:absolute; top:0; left:0; width: 65px; height: 65px; padding: 10px; font-size: 42px; line-height: 48px; text-align: center; color:#ffffff; background-color:#0ea94b; border-radius:50%; }
body.landscape .why3:before { content:"3"; position:absolute; top:0; left:0; width: 65px; height: 65px; padding: 10px; font-size: 42px; line-height: 48px; text-align: center; color:#ffffff; background-color:#0ea94b; border-radius:50%; }

@media only screen and (max-width: 575px) {
  body.landscape #hero .message {
    width: 90%;
    margin: 100px auto;
  }
  body.pricing #hero .message {
    width: 90%;
    margin: 100px auto;
  }
  body.landscape #hero h1 {
    font-size:1.8em;
  }
  body.landscape #hero h3 {
    font-size:1em;
  }
  body.landscape .btn-xl {
    font-size: .9rem;
  }
}

@media only screen and (max-width: 380px) {
  body.landscape #hero h1 { font-size: 2em; }
  .captcha-area { margin-left: -35px; }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .message {position:relative;top:300px;}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (min-width: 576px) {
    .message {position:relative;top:150px;}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (min-width: 768px) {
    .message {position:relative;top:200px;}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (min-width: 992px) {
    .message {position:relative;top:250px;}
}

