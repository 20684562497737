/* This stylesheet generated by Transfonter (https://transfonter.org) on December 19, 2017 3:44 PM */

@font-face {
    font-family: 'MontserratU';
    src: url('MontserratU.eot');
    src: url('MontserratU.eot?#iefix') format('embedded-opentype'),
        url('MontserratU.woff2') format('woff2'),
        url('MontserratU.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratU';
    src: url('MontserratU-Light.eot');
    src: url('MontserratU-Light.eot?#iefix') format('embedded-opentype'),
        url('MontserratU-Light.woff2') format('woff2'),
        url('MontserratU-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratU';
    src: url('MontserratU-Bold.eot');
    src: url('MontserratU-Bold.eot?#iefix') format('embedded-opentype'),
        url('MontserratU-Bold.woff2') format('woff2'),
        url('MontserratU-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'MontserratU-Bold';
    src: url('MontserratU-Bold.eot');
    src: url('MontserratU-Bold.eot?#iefix') format('embedded-opentype'),
        url('MontserratU-Bold.woff2') format('woff2'),
        url('MontserratU-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'Montserrat';
    src: url('MontserratU.eot');
    src: url('MontserratU.eot?#iefix') format('embedded-opentype'),
        url('MontserratU.woff2') format('woff2'),
        url('MontserratU.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('MontserratU-Bold.eot');
    src: url('MontserratU-Bold.eot?#iefix') format('embedded-opentype'),
        url('MontserratU-Bold.woff2') format('woff2'),
        url('MontserratU-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
